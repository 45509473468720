exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-features-cross-platform-tsx": () => import("./../../../src/pages/features/cross-platform.tsx" /* webpackChunkName: "component---src-pages-features-cross-platform-tsx" */),
  "component---src-pages-features-dapp-tsx": () => import("./../../../src/pages/features/dapp.tsx" /* webpackChunkName: "component---src-pages-features-dapp-tsx" */),
  "component---src-pages-features-hardware-wallet-tsx": () => import("./../../../src/pages/features/hardware-wallet.tsx" /* webpackChunkName: "component---src-pages-features-hardware-wallet-tsx" */),
  "component---src-pages-features-multisig-tsx": () => import("./../../../src/pages/features/multisig.tsx" /* webpackChunkName: "component---src-pages-features-multisig-tsx" */),
  "component---src-pages-features-portfolio-tsx": () => import("./../../../src/pages/features/portfolio.tsx" /* webpackChunkName: "component---src-pages-features-portfolio-tsx" */),
  "component---src-pages-features-staking-tsx": () => import("./../../../src/pages/features/staking.tsx" /* webpackChunkName: "component---src-pages-features-staking-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-serivce-tsx": () => import("./../../../src/pages/terms-serivce.tsx" /* webpackChunkName: "component---src-pages-terms-serivce-tsx" */)
}

